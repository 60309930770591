// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contactThanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-hello-js": () => import("./../../../src/pages/hello.js" /* webpackChunkName: "component---src-pages-hello-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-order-thanks-js": () => import("./../../../src/pages/orderThanks.js" /* webpackChunkName: "component---src-pages-order-thanks-js" */),
  "component---src-pages-philosophy-js": () => import("./../../../src/pages/philosophy.js" /* webpackChunkName: "component---src-pages-philosophy-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-rules-js": () => import("./../../../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-tokutei-js": () => import("./../../../src/pages/tokutei.js" /* webpackChunkName: "component---src-pages-tokutei-js" */)
}

